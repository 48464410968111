const getWebsocketBaseAddress = () => {
    const { host, protocol } = window.location;
    const wsProtocol = protocol === 'http:' ? 'ws:' : 'wss:';
    return `${wsProtocol}//${host}`;
};

const getBaseAddress = () => {
    const { host, protocol } = window.location;
    return `${protocol}//${host}`;
};

export { getWebsocketBaseAddress, getBaseAddress };

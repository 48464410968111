const baseRoutes = {
    actionTag: 'actiontags/tag',
    actionTags: 'actiontags',
    alarmLog: 'alarmLog',
    authentication: 'authentication',
    index: '/',
    login: '/login/:user',
    main: 'main',
    home: 'home',
    notFound: '*',
    reports: 'reports/requested',
    templates: 'reports/templates',
    wireless: 'wireless',
    wirelessDevice: 'wireless/device',
    activeAlarms: 'activeAlarms',
    users: 'users',
    changeOwnPassword: 'changeOwnPassword',
    devices: 'devices',
    smartphones: 'devices/:id',
    technicalAlarms: 'technicalalarms',
    saml2LoginReturn: 'saml2login',
};

export default baseRoutes;

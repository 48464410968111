import baseRoutes from 'configuration/routing/baseRoutes';
import { action, observable, runInAction } from 'mobx';
import { getAuthToken } from 'core/communcation/authTokenService';
import UserActivity from 'common/components/user-activity/containers/UserActivityManager';
import { redirect } from './../helpers/routerHelper';
import { getWebsocketBaseAddress } from './../helpers/urlHelper';
import WebsocketMessageBuilder from './websocketMessageBuilder';

export default class WebsocketStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    state = observable({
        connectionOpened: false,
        initializationInProgress: false,
        isReconnecting: false,
        error: null,
    });

    subscriptions = [];

    _keepAliveInterval = null;

    initialize = action(() => {
        if (
            this.state.initializationInProgress ||
            this.state.connectionOpened
        ) {
            return;
        }
        this.state.initializationInProgress = true;
        const address = `${getWebsocketBaseAddress()}/1/ws?_st=${getAuthToken()}`;
        this.websocket = new WebSocket(address);

        this.websocket.onopen = action(() => {
            this.state.connectionOpened = true;
            this.state.initializationInProgress = false;
            this._keepAlive();
        });

        this.websocket.onerror = action((e) => {
            this.state.error = e;
        });

        this.websocket.onclose = action(() => {
            this.state.connectionOpened = false;
            this.state.initializationInProgress = false;
            clearInterval(this._keepAliveInterval);
            redirect(baseRoutes.index);
        });

        this.websocket.onmessage = (e) => {
            const parsedMessage = JSON.parse(e.data);
            const keepAliveCheck = this._isKeepAliveResponse(parsedMessage);
            if (keepAliveCheck.status) {
                UserActivity.isActive = false;
                if (keepAliveCheck.code !== 200) {
                    clearInterval(this._keepAliveInterval);
                    runInAction(() => {
                        this.rootStore.environmentStore.state.loggedOutDueToInacvtivity = true;
                    });
                    redirect(baseRoutes.index);
                }
                return;
            }
            this.subscriptions.forEach((subscriber) =>
                subscriber.callback(parsedMessage),
            );
        };
    });

    subscribe = (subscriber) => {
        const isAlreadySubscribed = this.subscriptions.some(
            (item) => item.name === subscriber.name,
        );
        if (isAlreadySubscribed) {
            console.warn(
                'Provided subscriber is already on subscriptions list.',
            );
            return;
        }
        this.subscriptions.push(subscriber);
    };

    unsubscribe(subscriberName) {
        this.subscriptions = this.subscriptions.filter(
            (subscriber) => subscriber.name !== subscriberName,
        );
    }

    unsubscribeAll() {
        this.subscriptions = [];
    }

    send = (message) => {
        if (
            this.websocket.readyState === WebSocket.CLOSING ||
            this.websocket.readyState === WebSocket.CLOSED
        ) {
            return;
        }
        this.websocket.send(message);
    };

    close = (reason) => {
        try {
            if (
                this.websocket &&
                this.websocket.readyState === WebSocket.OPEN
            ) {
                this.websocket.close(1000, reason);
            }
        } catch (ex) {
            console.warn('Websocket connection was not closed properly.', ex);
        }
    };

    _keepAlive() {
        this._keepAliveInterval = setInterval(() => {
            try {
                const isUserActive = UserActivity.isActive;
                const messageBuilder = new WebsocketMessageBuilder();
                const keepAliveMessage = messageBuilder
                    .ofType('request')
                    .withId(13)
                    .withName('keepalive')
                    .withData({ isUserActive })
                    .build();
                this.send(keepAliveMessage);
            } catch (ex) {
                console.warn(ex);
            }
        }, 20000);
    }

    _isKeepAliveResponse(data) {
        const response = {
            status: false,
            code: 200,
        };
        if (!data) {
            return response;
        }
        response.status =
            data.length === 4 && data[0] === 'response' && data[1] === 13;
        response.code = data[2];
        return response;
    }
}

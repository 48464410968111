import React, { useEffect } from 'react';
import { Router, globalHistory } from '@reach/router';
import LoadableLogin from 'scenes/login/LoadableLogin';
import LoadableSaml2Login from 'scenes/login/LoadableSaml2Login';
import LoadableMain from 'scenes/main/LoadableMain';
import LoadableActionTagsList from 'scenes/main/action-tags/LoadableActionTags';
import LoadableActiveAlarms from 'scenes/main/active-alarms/LoadableActiveAlarms';
import LoadableTechnicalAlarms from 'scenes/main/active-alarms/LoadableTechnicalAlarms';
import LoadableAlarmLog from 'scenes/main/alarm-log/LoadableAlarmLog';
import LoadableHome from 'scenes/main/home/LoadableHome';
import {
    LoadableRequested,
    LoadableTemplates,
} from 'scenes/main/reports/LoadableReports';
import ChangeOwnPassword from 'scenes/main/security-configuration/ChangeOwnPassword';
import LoadableSecurityConfiguration from 'scenes/main/security-configuration/LoadableSecurityConfiguration';
import LoadableDevices from 'scenes/main/smartphones/pages/devices/LoadableDevices';
import LoadableSmartphones from 'scenes/main/smartphones/pages/smartphones/LoadableSmartphones';
import LoadableWirelessList from 'scenes/main/wireless/LoadableWireless';
import { useStore } from 'core/state/StoreProvider';
import baseRoutes from './baseRoutes';

const Routing = () => {
    const rootStore = useStore();
    useEffect(() => {
        const historyUnsubscribe = globalHistory.listen((e) => {
            rootStore.routerStore.setLocation(e.location.pathname, e.action);
        });
        rootStore.sessionManagerStore.onDemandPoll();
        return () => {
            historyUnsubscribe();
        };
    }, [rootStore]);

    return (
        <Router>
            <LoadableLogin default path={baseRoutes.index} />
            <LoadableLogin path={baseRoutes.login} />
            <LoadableSaml2Login exact path={baseRoutes.saml2LoginReturn} />
            <LoadableMain path={baseRoutes.main}>
                <LoadableHome default path={baseRoutes.home} />
                <LoadableAlarmLog path={baseRoutes.alarmLog} />
                <LoadableSecurityConfiguration path={baseRoutes.users} />
                <LoadableActiveAlarms path={baseRoutes.activeAlarms} />
                <ChangeOwnPassword path={baseRoutes.changeOwnPassword} />
                <LoadableActionTagsList path={baseRoutes.actionTags} />
                <LoadableWirelessList path={baseRoutes.wireless} />
                <LoadableRequested path={baseRoutes.reports} />
                <LoadableTemplates path={baseRoutes.templates} />
                <LoadableDevices path={baseRoutes.devices} />
                <LoadableSmartphones path={baseRoutes.smartphones} />
                <LoadableTechnicalAlarms path={baseRoutes.technicalAlarms} />
            </LoadableMain>
        </Router>
    );
};

export default Routing;

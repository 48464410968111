import { useEffect } from 'react';
import { useStore } from 'core/state/StoreProvider';

const LoadableSaml2Login = (props) => {
    const rootStore = useStore();

    useEffect(() => {
        const searchParams = new URLSearchParams(props.location.search);
        const authenticationSuccessful =
            searchParams && searchParams.get('success') === '1';

        if (authenticationSuccessful) {
            const userName = searchParams.get('userName');
            rootStore.loginStore.handlePostIdentityProviderLogin(userName);
            return;
        }
        rootStore.loginStore.handlePostIdentityProviderLoginError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default LoadableSaml2Login;
